.gallery-wrapper
    display: flex
    position: relative
    margin-left: 100%
    width: 100vw
    &:nth-child(n)
        animation: slide-in 0.5s forwards
    &:nth-child(n+1)
        animation: slide-in 0.5s forwards 0.2s
    &:nth-child(n+2)
        animation: slide-in 0.5s forwards 0.4s
    &:nth-child(n+3)
        animation: slide-in 0.5s forwards 0.6s
    &:nth-child(n+4)
        animation: slide-in 0.5s forwards 0.8s
    &:nth-child(n+5)
        animation: slide-in 0.5s forwards 1s
    
@keyframes slide-in
    from
        margin-left: 100%
    to
        margin-left: 0%

.gallery-content
    display: flex
    flex-direction: row
    position: relative
    width: 100%
    overflow-x: scroll
    scroll-snap-type: x mandatory
    scroll-behavior: smooth

.gallery-element
    display: flex
    position: relative
    min-width: 100%
    max-width: 100%
    overflow: hidden
    scroll-snap-align: start

.side-icon
    display: flex
    position: absolute
    justify-content: center
    align-items: center
    font-size: 100px
    height: 100%
    padding-left: 30px
    padding-right: 30px
    z-index: 1
    @media (max-width: 1500px)
        font-size: 70px
    @media (max-width: 700px)
        font-size: 30px

.icon
    color: rgba(255, 255, 255, 0.5)
    cursor: pointer
    transition: color 200ms ease-in-out
    &:hover
        color: rgba(255, 255, 255, 1)

.left
    left: 0

.right
    right: 0


::-webkit-scrollbar 
    display: none