@import ../Vars
    
.tile-wrapper
    display: flex
    flex: 1
    min-height: 300px
    padding-left: 15px
    padding-right: 15px
    max-width: 100%
    overflow: hidden
    background-color: transparent
    @media (max-width: 1600px)
        min-height: 200px
    @media (max-width: 700px)
        min-height: 150px

.tile-content
    display: flex
    flex: 1
    padding: 50px 100px 50px 100px
    border-radius: 30px
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.49)
    margin-top: 5px
    margin-bottom: 5px
    max-width: 100%
    overflow: hidden
    @media (max-width: 1600px)
        padding: 40px 80px 40px 80px
    @media (max-width: 700px)
        padding: 20px 40px 20px 40px

.style-1
    background-color: var(--tbg-1)
    color: white

.style-2
    background-color: var(--tbg-2)
    color: white

.style-3
    background-color: var(--tbg-3)
    color: white

.style-4
    background-color: var(--tbg-4)
    color: white

.style-5
    background-color: var(--tbg-5)
    color: black