.loader
    display: flex
    border: 5px solid #f3f3f3
    border-top: 5px solid #3498db
    border-radius: 50%
    height: 20px
    width: 20px
    animation: spin 2s linear infinite

.fallback 
    display: flex
    height: 100vh
    width: 100%
    justify-content: center
    align-items: center
    text-align: center

.fallback-content
    display: flex
    position: relative
    justify-content: center
    align-items: center
    height: 100%
    width: 50%

.fallback-img 
    display: flex
    position: absolute
    height: 200px
    width: 200px

.fallback-loader 
    display: flex
    position: absolute
    border: 16px solid #f3f3f3
    border-top: 16px solid #3498db
    border-radius: 50%
    height: 300px
    width: 300px
    animation: spinFall 2s linear infinite

@keyframes spinFall
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)