.body-wrapper
    display: flex
    flex-direction: column
    position: relative
    justify-content: center
    min-height: calc(100vh - 50px)
    margin-top: 15px
    margin-bottom: 15px
//     &:nth-child(n) > .gallery-wrapper
//         animation: slide-in 0.5s forwards
//     &:nth-child(n+1) > .gallery-wrapper
//         animation: slide-in 1s forwards
//     &:nth-child(n+2) > .gallery-wrapper
//         animation: slide-in 1.5s forwards
//     &:nth-child(n+3) > .gallery-wrapper
//         animation: slide-in 2s forwards
    
// @keyframes slide-in
//     from
//         margin-left: 100%
//     to
//         margin-left: 0%