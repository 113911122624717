$bg-color: #fff
$tbg-1: #4a707a
$tbg-2: #7697a0
$tbg-3: #94b0b7
$tbg-4: #c2c8c5
$tbg-5: #eff1f0
    
body
    --bg-color: #{$bg-color}
    --tbg-1: #{$tbg-1}
    --tbg-2: #{$tbg-2}
    --tbg-3: #{$tbg-3}
    --tbg-4: #{$tbg-4}
    --tbg-5: #{$tbg-5}

body.dark
    --bg-color: #222
    --tbg-1: #{darken($tbg-1, 30%)}
    --tbg-2: #{darken($tbg-2, 35%)}
    --tbg-3: #{darken($tbg-3, 40%)}
    --tbg-4: #{darken($tbg-4, 45%)}
    --tbg-5: #{white}