@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200&family=Caveat&family=Permanent+Marker&family=Roboto:wght@100&family=Inconsolata:wght@200&display=swap')

.title-tile
    display: flex
    flex: 1
    flex-direction: column
    justify-content: center

.title-tile-header
    display: flex
    flex-direction: row
    // font-family: 'Manrope', sans-serif
    font-family: 'Caveat', cursive
    font-weight: 600
    font-size: 120px
    @media (max-width: 1500px)
        font-size: 70px
    @media (max-width: 700px)
        font-size: 40px

.title-tile-line
    display: flex
    align-items: center
    white-space: nowrap
    font-family: 'Caveat', cursive
    // font-family: 'Roboto', sans-serif
    font-size: 40px
    @media (max-width: 1500px)
        font-size: 30px
    @media (max-width: 700px)
        font-size: 20px

.headline
    font-family: 'Permanent Marker', cursive
    font-size: 140px
    @media (max-width: 1500px)
        font-size: 50px

.content-tile
    display: flex
    flex-direction: row
    overflow: hidden
    flex: 1

.content-tile-main
    display: flex
    flex-direction: column
    justify-content: center

.content-tile-sidebar
    display: flex
    flex: 1
    flex-direction: column
    align-items: flex-end
    justify-content: center
    > svg
        margin: 10px
        height: 150px
        width: 200px
        @media (max-width: 1500px)
            height: 75px
            width: 100px
        @media (max-width: 700px)
            margin: 7px
            height: 50px
            width: 75px

.content-tile-header
    display: block
    font-family: 'Permanent Marker', cursive
    font-size: 40px
    white-space: nowrap
    line-height: 1
    margin-bottom: 10px
    > a
        font-family: inherit
        &:-webkit-any-link
            text-decoration: none
    @media (max-width: 1500px)
        font-size: 30px
        > a
            font-size: 30px
    @media (max-width: 700px)
        font-size: 25px
        white-space: normal
        > a
            font-size: 25px

.content-tile-body
    display: flex
    flex-direction: column
    font-family: 'Consolas', monospace
    font-size: 20px
    white-space: pre-wrap
    @media (max-width: 1500px)
        font-size: 15px
    @media (max-width: 700px)
        font-size: 10px
    > a
        font-family: 'Consolas', monospace

.social-link
    display: block
    width: 100%
    max-height: 200px
    font-family: 'Permanent Marker', cursive
    font-size: 40px
    > a
        font-family: inherit
        &:-webkit-any-link
            text-decoration: none
            color: white
        > svg
            justify-content: center
            align-items: center
            &:hover
                filter: grayscale(50%)

.m3-icons
    > svg
        height: 100px
        width: 150px
        @media (max-width: 1500px)
            height: 50px
            width: 75px
        @media (max-width: 700px)
            height: 25px
            width: 50px

.contact-image
    width: 100%
    overflow: hidden
    > img
        width: 100%

.contact-tile-header
    text-align: center

.tiles-icon
    height: 5rem !important
    width: 5rem !important
    @media (max-width: 1500px)
        height: 4rem !important
        width: 4rem !important
    @media (max-width: 700px)
        height: 3rem !important
        width: 3rem !important

.tiles-icon.l
    height: 8rem !important
    width: 8rem !important
    @media (max-width: 1500px)
        height: 7rem !important
        width: 7rem !important
    @media (max-width: 700px)
        height: 6rem !important
        width: 6rem !important

.tiles-icon-frame
    display: flex
    justify-content: center
    align-items: center
    height: 6rem
    width: 6rem
    margin: 1rem
    background-color: white
    border-radius: 50%
    @media (max-width: 1500px)
        height: 5rem !important
        width: 5rem !important
        margin: 0.5rem
    @media (max-width: 700px)
        height: 4rem !important
        width: 4rem !important
        margin: 0.3rem

.tiles-icon-frame.l
    height: 9rem
    width: 9rem
    @media (max-width: 1500px)
        height: 8rem !important
        width: 8rem !important
    @media (max-width: 700px)
        height: 7rem !important
        width: 7rem !important