@import ./Vars

*
    font-family: 'Montserrat', sans-serif
    font-weight: 300

body
    margin: 0
    padding: 0
    background-color: var(--bg-color)


.material-icons.md-18
    font-size: 18px

.material-icons.md-24
    font-size: 24px

.material-icons.md-36
    font-size: 36px
    @media (max-width: 1500px)
        font-size: 36px
    @media (max-width: 700px)
        font-size: 24px

.material-icons.md-48
    font-size: 48px

::-webkit-scrollbar
    width: 0px
  
::-webkit-scrollbar-track
    border-radius: 0px
    background: transparent
  
::-webkit-scrollbar-thumb
    border-radius: 0px
    background: transparent

@keyframes blur-background
    0%
        -webkit-backdrop-filter: blur(10px)
        backdrop-filter: blur(10px)
    100%
        -webkit-backdrop-filter: blur(0px)
        backdrop-filter: blur(0px)